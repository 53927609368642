body {
  margin: 0;
  font-family: "Roboto" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  width: 0.3em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  outline: 1px solid slategrey;
  background-color: #141414;
}

body::-webkit-scrollbar-thumb {
  background-color: #fff;
  outline: 1px solid slategrey;
}
.tabPannel {
  flex-grow: 1 !important;
}
#myCanvas img {
  margin-left: 450;
  width: 800px;
}
.box-paper {
  position: relative;
}
.main-container {
  display: flex;
  flex-direction: row;
  background-color: #161618;
}

.review-bar {
  margin-bottom: 1rem;
  height: 70vh;
  overflow-y: auto;
}
.right-area {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  position: relative;
  width: calc(100% - 450px);
  background: #71797e;
  align-items: center;
  justify-content: center;
  padding: 2em;
  margin-top: 2rem;
  margin-left: 450px;
}
.image {
  width: 100%;
  max-height: 100%;
}
.value-button {
  display: flex;
  border-radius: 10px;
  font-size: 13px;
  margin-right: 15px;
  height: 3em;
  flex: 0;
}
.value-button:nth-child(odd) {
  background-color: #161618;
}

.value-button:nth-child(even) {
  background-color: #1e1e1e;
}

.first-value-button {
  display: block;
  margin-left: 15px;
}
.buttons-bar {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 30px;
  width: 400px;
  height: max-content;
}
.action-button {
  margin: 10px;
}
.review-heading-section {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.review-heading {
  color: #fff;
}
.status-chip {
  position: absolute;
  top: 0px;
  right: 50px;
  left: 50px;
}
.MuiDialog-container {
  border-radius: 15px !important;
}
.MuiDialog-paper {
  border-radius: 15px !important;
}

.highlightable::selection {
  background-color: pink;
}
.MuiInputBase-input {
  background-color: transparent !important;
}

.input-webkit-autofill {
  background-color: transparent !important;
}

.form {
  display: flex;
  flex-direction: column;
}
.form-container {
  width: 300px;
  overflow: hidden;
  transition: height 0.3s;
}
.sign-in {
  height: 400px;
}
.sign-up {
  height: 490px;
}
.forget-password {
  height: 400px;
}

.highlightable::selection {
  background-color: pink;
}

.first-input {
  width: 180px;
  padding: 0px;
  margin-right: 1rem;
  margin-left: 0.7rem;
  overflow: hidden;
}
.input-field-style {
  border: none;
  height: 45px;
  color: #a7a7a7;
  font-size: 13px;
  background-color: transparent;
  text-align: left;
  outline: none;
  padding: 0;
  padding-right: 10px;
  padding-left: 10px;
}
.box-style {
  height: 40px;
  background: transparent;
  width: 200px;
  text-align: left;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.2rem;
}

.paragraph-style {
  margin-top: 15px;
  margin-left: 15px;
  color: #a7a7a7;
  display: inline-flex;
}
.dropdown-wrapper {
  width: 185px;
  margin-top: 0.2rem;
  text-align: center;
  padding-left: 0px;
  overflow: hidden;
  margin-left: 0.4rem;
}

.docType-wrapper {
  width: 200px;
  overflow: hidden;
}
.input-field {
  width: 160px;
  border: none;
  height: 50px;
  color: #a7a7a7;
  background-color: transparent;
  outline: none;
  padding-right: 15px;
  display: flex;
  align-items: center;
  text-align: left;
  margin-left: 9px;
}
.leftDrawer-image {
  height: 3rem;
}
.input-wrapper {
  display: flex;
  align-items: center;
  width: "185px";
}
.details-heading {
  margin-left: 1em;
  width: 80%;
  color: white;
  font-size: 15px;
}
@media screen and (min-width: 630px) {
  .left-area {
    display: flex;
    height: 100%;
    flex: 0 1 auto;
    flex-direction: column;
    width: 450px;
    z-index: 100;
    background: #161618;
    align-items: center;
    margin-top: 2rem;
    /* position: relative; */
  }
}
@media screen and (max-width: 640px) {
  .left-area {
    display: flex;
    height: "100vh";
    overflow-y: scroll;
    flex-direction: column;
    width: 100vw;
    z-index: 100;
    background: #161618;
  }
  .buttons-bar {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 30px;
    width: 100vw;
  }
  .checkbox-style {
    margin-bottom: 6rem;
  }
  .first-value-button {
    display: block;
    margin-left: 15px;

    width: 90%;
  }
  .value-button {
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: 0% 6%;
    padding-top: 0.5em;
    height: 4em;

    border-radius: 10px;
    flex: 0;
  }
  .value-button:nth-child(odd) {
    background-color: #161618;
  }

  .value-button:nth-child(even) {
    background-color: #1e1e1e;
  }
  .first-input {
    padding: 0px;
    margin-right: 1rem;
    overflow: hidden;
    margin-left: 3rem;
  }
  .box-style {
    height: 20px;
    width: 90%;
    margin-top: 0.2rem;
  }
  .input-wrapper {
    display: flex;
    align-items: center;
    width: "300px";
  }
  .input-field-style {
    height: 34px;
    margin-left: 2.5rem;
    width: 80%;
  }
  .dropdown-wrapper {
    height: 34px;
    margin-left: 3rem;
    width: 80%;
  }
  .input-field {
    height: 34px;
    padding-left: 2.5rem;
    width: 80%;
  }
  .review-bar {
    height: 65vh;
  }
  .loader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
  }
  .docType-wrapper {
    margin-left: 3rem;
    width: 80%;
  }
}
@media screen and (max-width: 1192px) {
  .leftDrawer-image {
    height: 1.7rem !important;
  }
}
@media screen and (max-width: 850px) {
  /* .left-area {
    width: 380px;
  } */
  .value-button {
    width: 85%;
    margin-left: 6%;
  }
  .details-heading {
    width: 85%;

    margin-left: 8%;
  }
}
/* @media screen and (max-width: 600px) {
  .left-area {
    width: 100%;
  }
} */
.form-control {
  background-color: #141414;
  border: 1px solid #fff;
  height: 2rem;
  color: #a7a7a7;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 10px;
}
.react-datepicker {
  border-radius: 20px !important;
  border: none !important;
}
.react-datepicker__month-container {
  background-color: #282828;
  border-radius: 20px;
}
.react-datepicker__day {
  color: #a7a7a7 !important;
}
.react-datepicker__day:not(.react-datepicker__day--disabled):hover {
  background-color: #141414 !important;
}
.react-datepicker__day--disabled {
  background-color: #a7a7a719 !important;
  cursor: not-allowed !important;
  color: #606365 !important;
}
