.image-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.image-container.enlarged {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
}
.splitImage-container.splitEnlarged {
  position: absolute;
  top: -3em;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}
.duplicateImage-container.duplicateEnlarged {
  position: absolute;
  top: -3em;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.duplicateImage-container.duplicateEnlarged img {
  max-height: 100%;
  max-width: 100%;
}
.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.splitImage-container img {
  max-width: 100%;
  max-height: 100%;
}